import React from 'react'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'contexts/Localization'
import Page, { PageMaxWidth } from 'components/layout/Page'
import TopBanner from './components/TopBanner'
import Statistic from './components/Statistic'
// import InnovationPools from '../Farms/InnovationPools'
import { useFetchFarmsPublicData, useFetchFarmUserData } from '../../state/farms/hooks'
import FeaturedPools from '../Farms/FeaturedPools'
import TipRow from './components/TipRow'

const Home: React.FC = () => {
  const { t } = useTranslation()
  useFetchFarmsPublicData()
  useFetchFarmUserData()
  const history = useHistory()
  history.listen(route => {
    window.scrollTo(0,0)
  });
  return (
    <PageMaxWidth>
      <TopBanner/>
      <Statistic/>
      <TipRow />
      <FeaturedPools/>
      {/*
      <InnovationPools/>
      */}
    </PageMaxWidth>
  )
}

export default Home
