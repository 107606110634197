import { useCallback, useEffect, useState } from 'react'
import BigNumber from 'bignumber.js'
import { Contract } from 'ethers'

import { useFarms } from '../../../state/farms/hooks'
import { getCoinAddress, getWbnbAddress } from '../../../utils/addressHelpers'
import { getBep20Contract } from '../../../utils/contractHelpers'
import { useLpContract } from '../../../hooks/useContract'
import { useGetBNBPrice, useGetTFITokenPrice } from '../../../hooks/useTokenBalance'

export const useGetTVL = () => {
  const [num,setNum] = useState(0)
  const { data: farmsLP } = useFarms()
  const bnbPrice = useGetBNBPrice()
  const bnbAddress = getWbnbAddress()

  const fetchAllStakedValue = useCallback(async () => {
    let num1 = new BigNumber(0);
    let num2 = new BigNumber(0);
    const stakeAmountArr = farmsLP.map((farm , i)=>{
      return new BigNumber(farm.stakeAmount).div(new BigNumber(10).pow(farm.stakeTokenDecimals))
    })
    stakeAmountArr.forEach((v,i)=>{
      num2 = num2.plus(v)
      if(farmsLP[i].poolType===0){
        const sp = new BigNumber(farmsLP[i].poolViewInfo.stakeUsdtPrice)
        if(farmsLP[i].stakedPairUsdtAddress.toLowerCase() === bnbAddress.toLowerCase()){
          num1 = num1.plus(new BigNumber(v).times(sp).times(bnbPrice))
        }else {
          num1 = num1.plus(new BigNumber(v).times(sp))
        }
        // console.log('---',farmsLP[i].pid,new BigNumber(v).times(sp).times(bnbPrice).toNumber(),new BigNumber(v).times(sp).toNumber())
      }else{
        const sp = new BigNumber(farmsLP[i].poolViewInfo.lpTotalInQuoteToken)
        if(farmsLP[i].stakedPairUsdtAddress.toLowerCase() === bnbAddress.toLowerCase()){
          num1 = num1.plus(sp.times(bnbPrice))
        }else {
          num1 = num1.plus(sp)
        }
        // console.log('---',farmsLP[i].pid,sp.times(bnbPrice).toNumber(),sp.toNumber(),)

      }

    })
    if(!num1.isNaN()){
      setNum(new BigNumber(num1.toFixed(3)).toNumber())
    }

  }, [ setNum, farmsLP,bnbPrice,bnbAddress])

  useEffect(() => {
    fetchAllStakedValue()
  }, [fetchAllStakedValue])
  return num
}

export const useGetTFITotalSupply = ()=>{
  const [supply,setSupply] = useState(0)
  useEffect(() => {
    const fetchSupply = async () => {
      const coinAddress = getCoinAddress()
      if(coinAddress==='') return
      const contract = getBep20Contract(coinAddress)
      const decimals = await contract.decimals();
      const totalSupply = await contract.totalSupply();
      const supplyNew = new BigNumber(totalSupply.toString()).div(new BigNumber(10).pow(decimals)).toNumber()
      setSupply(supplyNew)
    }
    fetchSupply()
  }, [ setSupply])
  return supply
}

export const getCheckPair = async(UniswapV2PairContract:Contract)=>{
  try{
    const token0 =  await UniswapV2PairContract.token0();
    if(token0[0]){
      return true
    }
  }catch (e) {
    return false
  }
  return false
}

export const useCirculation = ()=>{
  const [circulationV,setCirculation] = useState(0)
  const [marketCapV,setMarketCap] = useState(0)
  const tfiPrice = useGetTFITokenPrice()
  const curChainName = process.env.REACT_APP_CHAIN_NAME
  useEffect(() => {
    const fetchCirculation = async () => {
      const url = "https://raw.githubusercontent.com/Sitcum/staking-front-config/main/config.json"
      try {
        const response = await fetch(url,{
          mode: 'cors',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded'
          },
        })
        if (!response.ok) {
          console.error(response)
          return
        }
        const data = await response.json()
        const circulationData = data.circulation[curChainName]
        const marketCapData = new BigNumber(circulationData).times(new BigNumber(tfiPrice)).toNumber()
        setCirculation(circulationData)
        setMarketCap(marketCapData)
      }catch (e) {
        console.error(e)
        console.error('can not get the config.json')
      }
    }
    fetchCirculation()
  }, [ setCirculation,tfiPrice,curChainName,setMarketCap])
  return {circulation:circulationV,marketCap:marketCapV}
}
