import React, { useState } from 'react'
import BigNumber from 'bignumber.js'
import styled, { keyframes,css } from 'styled-components'
import { Flex, Text, Skeleton } from 'trustfi-uikit'
import { Farm } from 'state/types'
import { provider as ProviderType } from 'web3-core'
import { useTranslation } from 'contexts/Localization'
import ExpandableSectionButton from 'components/ExpandableSectionButton'
import { BASE_ADD_LIQUIDITY_URL } from 'config'
import getLiquidityUrlPathParts from 'utils/getLiquidityUrlPathParts'
import { useWeb3React } from "@web3-react/core";

import DetailsSection from './DetailsSection'
import CardHeading from './CardHeading'
import CardActionsContainer from './CardActionsContainer'
import ApyButton from './ApyButton'
import { walletSet } from "../../../../components/Menu/config";
import { getExplorerUrl } from '../../../../utils/formatUtil'
import { useGetPoolTVL } from '../../hooks/helpers'

export interface FarmWithStakedValue extends Farm {
  apr?: number
  liquidity?: BigNumber
}

const StyledContainer = styled.div`
  height: 100%;
  min-height: 437px;
  display: flex;
  flex-direction: column;
`
const StyledFCardBox = styled.div`
  height: 100%;
  background: ${({theme}) => theme.expandColor.color.pageBg};
  border: 2px solid ${({theme}) => theme.expandColor.color.active};
  border-radius: ${({ theme }) => theme.expandColor.radius.radius4};
  min-height: 437px;
  display: flex;
  flex-direction: column;
`
const AccentGradient = keyframes`  
  0% {
    background-position: 50% 0%;
  }
  50% {
    background-position: 50% 100%;
  }
  100% {
    background-position: 50% 0%;
  }
`

const StyledCardAccent = styled.div`
  background: ${({ theme }) => `linear-gradient(180deg, ${theme.colors.primaryBright}, ${theme.colors.secondary})`};
  background-size: 400% 400%;
  animation: ${AccentGradient} 2s linear infinite;
  border-radius: 32px;
  position: absolute;
  top: -1px;
  right: -1px;
  bottom: -3px;
  left: -1px;
  z-index: -1;
`

const FCard = styled.div<{ isPromotedFarm: boolean }>`
  flex: 2;
  display: flex;
  flex-direction: column;
  padding: 24px;
  position: relative;
  text-align: center;
  height: 100%;
  min-height: 366px;
`

const Divider = styled.div`
  background-color: ${({ theme }) => theme.colors.borderColor};
  height: 1px;
  margin: 28px auto;
  width: 100%;
`

const expandAnimation = keyframes`
  from {
    max-height: 0px;
  }
  to {
    max-height: 500px;
  }
`

const collapseAnimation = keyframes`
  from {
    max-height: 500px;
  }
  to {
    max-height: 0px;
  }
`

const ExpandingWrapper = styled.div<{ expanded: boolean }>`
  overflow: hidden;
  animation: ${({ expanded }) =>
  expanded
    ? css`
          ${expandAnimation} 300ms linear forwards
        `
    : css`
          ${collapseAnimation} 300ms linear forwards
        `};
  // height: ${(props) => (props.expanded ? '100%' : '0px')};

`

interface FarmCardProps {
  farm: FarmWithStakedValue
  removed: boolean
  tokenPrice?: BigNumber
  provider?: ProviderType
  account?: string
}

const FarmCard: React.FC<FarmCardProps> = ({ farm, removed, tokenPrice, account }) => {
  const { t } = useTranslation()
  const { chainId } = useWeb3React()

  const [showExpandableSection, setShowExpandableSection] = useState(false)

  const farmImage = farm.poolViewInfo&&farm.poolViewInfo.stakedLogo

  const totalValueFormatted = farm.liquidity
    ? `$${farm.liquidity.toNumber().toLocaleString(undefined, { maximumFractionDigits: 0 })}`
    : '-'

  const lpLabel = farm.stakeTokenSymbol && farm.stakeTokenSymbol.toUpperCase()
  const earnLabel = farm.rewardTokenSymbol && farm.rewardTokenSymbol.toUpperCase()

  const farmAPR = farm.apr && farm.apr.toLocaleString('en-US', { maximumFractionDigits: 2 })

  const liquidityUrlPathParts = ''
  const addLiquidityUrl = `${BASE_ADD_LIQUIDITY_URL}/${liquidityUrlPathParts}`
  const isPromotedFarm = false
  const explorerUrl = getExplorerUrl(chainId)
  const lpContractUrl = explorerUrl
  const { endBlock } = farm

  // get this pool tvl
  const poolTvl = useGetPoolTVL(farm.pid)

  return (
    <StyledContainer>
      <StyledFCardBox>
        <FCard isPromotedFarm={isPromotedFarm}>
          <CardHeading
            lpLabel={lpLabel}
            farmImage={farmImage}
            tokenSymbol={lpLabel}
            earnLabel={earnLabel}
            poolName={farm.poolName}
            isErrorPool={farm.isErrorPool}
            rewardTokenImg={farm.poolViewInfo.rewardLogo}
          />
          <Flex justifyContent="space-between">
            <Text bold color="white" fontSize="18px">{t('Stake')}</Text>
            <Text bold color="white" fontSize="18px">{lpLabel}</Text>
          </Flex>
          <Flex justifyContent="space-between">
            <Text bold color="white" fontSize="18px">{t('Earn')}</Text>
            <Text bold color="white" fontSize="18px">{earnLabel}</Text>
          </Flex>
          <Flex justifyContent="space-between" alignItems="center">
            <Text bold color="white" fontSize="18px">{t('APR')}</Text>
            <Text bold style={{ display: 'flex', alignItems: 'center' }} fontSize="18px">
              {farm.apr ? (
                <>
                  <ApyButton
                    lpLabel={lpLabel}
                    addLiquidityUrl={addLiquidityUrl}
                    tokenPrice={new BigNumber(farm.poolViewInfo.rewardUsdtPrice)}
                    apr={farm.apr}
                    earningTokenSymbol={farm.rewardTokenSymbol}
                  />
                  {farmAPR}%
                </>
              ) : (
                <Skeleton height={24} width={80} />
              )}
            </Text>
          </Flex>

          <Flex justifyContent="space-between">
            <Text bold color="white" fontSize="18px">{t('TVL')}</Text>
            <Text bold color="white" fontSize="18px">{poolTvl}$</Text>
          </Flex>

          <CardActionsContainer farm={farm} account={account} addLiquidityUrl={addLiquidityUrl} />
        </FCard>
        <ExpandableSectionButton
          onClick={() => setShowExpandableSection(!showExpandableSection)}
          expanded={showExpandableSection}
        />
        <ExpandingWrapper expanded={showExpandableSection}>
          <DetailsSection
            removed={removed}
            totalValueFormatted={totalValueFormatted}
            lpLabel={lpLabel}
            addLiquidityUrl={addLiquidityUrl}
            endBlock={endBlock}
            mayEndTime={farm.mayEndTime}
            poolOwner={farm.poolOwner}
            farm={farm}
          />
        </ExpandingWrapper>
      </StyledFCardBox>
    </StyledContainer>
  )
}

export default FarmCard
