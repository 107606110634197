import { MenuEntry, Socials } from 'trustfi-uikit'
import { ContextApi } from 'contexts/Localization/types'

import webIcon from '../../assets/logo/logo-200x200.png'
import Home from '../../assets/navIcon/v1/1.svg'
import QRTest from '../../assets/config/qr-test.png'

import Facebook from '../../assets/navIcon/v1/13.svg'

export const links: (t: ContextApi['t']) => MenuEntry[] = (t) => [
  {
    label: t('Home'),
    icon: 'HomeIcon',
    href: '/',
    iconLink: Home,
    iconSize: 24,
    labelStringId: 'Home',
  },

]

export const socials: Socials[] = []

export const webAsset = {
  Images: {
    webIconLight: webIcon,
    webIconDark: webIcon
  },
  webIcon: {
    width: 150,
    height: 150,
    light: webIcon,
    dark: webIcon
  },
  tokenAsset: {
    tokenIcon: webIcon,
    priceLink: ''
  }
}

const walletSetMain = {
  scanLabel: 'View On HECO',
  scanLink: 'https://hecoinfo.com/address/',
  helpLink: 'https://ethereum.org/wallets/',
  scanLinks: {
    56: 'https://bscscan.com/address/',
    97: 'https://testnet.bscscan.com/address/',
    86: 'https://gatescan.org/address/',
    85: 'https://gatescan.org/testnet/address/',
    321:'https://explorer.kcc.io/address/',
    322:'https://scan-testnet.kcc.network/address/',
    66: 'https://www.oklink.com/oec/address/',
    65: 'https://www.oklink.com/oec-test/address/',
    128:'https://hecoinfo.com/address/',
    256:'https://testnet.hecoinfo.com/address/',
    250:'https://ftmscan.com/address/',
    4002: 'https://faucet.fantom.network/address/',
  }
}
const walletSetTest = {
  scanLabel: 'View On HECO TestNet',
  scanLink: 'https://testnet.hecoinfo.com/address/',
  helpLink: 'https://ethereum.org/wallets/',
  scanLinks: {
    56: 'https://bscscan.com/address/',
    97: 'https://testnet.bscscan.com/address/',
    86: 'https://gatescan.org/address/',
    85: 'https://gatescan.org/testnet/address/',
    321:'https://explorer.kcc.io/address/',
    322:'https://scan-testnet.kcc.network/address/',
    66: 'https://www.oklink.com/oec/address/',
    65: 'https://www.oklink.com/oec-test/address/',
    128:'https://hecoinfo.com/address/',
    256:'https://testnet.hecoinfo.com/address/',
    250:'https://ftmscan.com/address/',
    4002: 'https://faucet.fantom.network/address/',
  }
}
export const walletSet = walletSetMain

export const contactList = [
  {
    href: "https://www.facebook.com/",
    iconLink: Facebook,
    iconSize: 24,
    isTooltip: false,
    tooltipContent: QRTest,
    isPhoto: true,
  },
]
