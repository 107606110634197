import BigNumber from 'bignumber.js'
import erc20ABI from 'vipswap/lib/abi/erc20.json'
import lpTokenAbi from 'vipswap/lib/abi/uni_v2_lp.json'
import stakingFactoryABI from 'vipswap/lib/abi/TrustFiStakingFactory.json'
import multicall from 'utils/multicall'
import { Farm, FarmUserData } from '../types'
import factoryCoreABI from '../../vipswap/lib/abi/TrustFiStakingFactoryCore.json'

// lptoken
export const fetchFarmUserLp = async (factoryAddress: string,pidV:number,token: string,account: string) => {
  const calls = [
    {
      address: token,
      name: 'allowance',
      params: [account, factoryAddress]
    },
    {
      address: token,
      name: 'balanceOf',
      params: [account],
    },
  ]
  const [
    rawLpAllowance,
    rawLpBalances,

  ] = await multicall(lpTokenAbi, calls)
  const calls2 = [
    {
      address: factoryAddress,
      name: 'getUserStakeInfo',
      params: [pidV, account]
    },
    {
      address: factoryAddress,
      name: 'pendingRewardV3',
      params: [pidV, account]
    },
    {
      address: factoryAddress,
      name: 'userStakeRange',
      params: [pidV, account]
    },
    {
      address: factoryAddress,
      name: 'core',
      params: [],
    },
  ]
  const [
    userStakeInfo,
    userRewardInfo,
    userStakeRange,
    coreAddress,
  ] = await multicall(stakingFactoryABI, calls2)
  const calls3 = [
    {
      address: coreAddress[0],
      name: 'getUserStakeInfo',
      params: [pidV,account],
    },
  ]
  const [
    getUserStakeInfo,
  ] = await multicall(factoryCoreABI,calls3)
  const res:FarmUserData=  {
    pid: pidV,
    allowance: new BigNumber(rawLpAllowance).toJSON(),
    balance: new BigNumber(rawLpBalances).toJSON(),
    stakeAmount: new BigNumber(userStakeInfo.stakeAmount.toString()).toString(),
    stakePower: new BigNumber(userStakeInfo.stakePower.toString()).toString(),
    reward: new BigNumber(userRewardInfo.stakePendingRewardsRet.toString()).toString(),
    userMinStakeAmount: new BigNumber(userStakeRange.userMinStakeAmount.toString()).toString(),
    userMaxStakeAmount: new BigNumber(userStakeRange.userMaxStakeAmount.toString()).toString(),
    lastStakeTime: new BigNumber(getUserStakeInfo[0].lastStakeTime.toString()).times(1000).toNumber(),
  }
  return res
}
// token allowance
export const fetchFarmUserToken = async (factoryAddress: string,pidV: number,token: string,account: string) => {
  const calls = [
    {
      address: token,
      name: 'allowance',
      params: [account, factoryAddress]
    },
    {
      address: token,
      name: 'balanceOf',
      params: [account],
    },
  ]
  const [
    rawTokenAllowance,
    rawTokenBalances,
  ] = await multicall(erc20ABI, calls)
  const calls2 = [
    {
      address: factoryAddress,
      name: 'getUserStakeInfo',
      params: [pidV, account]
    },
    {
      address: factoryAddress,
      name: 'pendingRewardV3',
      params: [pidV, account]
    },
    {
      address: factoryAddress,
      name: 'userStakeRange',
      params: [pidV, account]
    },
    {
      address: factoryAddress,
      name: 'core',
      params: [],
    },
  ]

  const [
    userStakeInfo,
    userRewardInfo,
    userStakeRange,
    coreAddress,
  ] = await multicall(stakingFactoryABI, calls2)

  const calls3 = [
    {
      address: coreAddress[0],
      name: 'getUserStakeInfo',
      params: [pidV,account],
    },
  ]
  const [
    getUserStakeInfo,
  ] = await multicall(factoryCoreABI,calls3)
  const res:FarmUserData= {
    pid: pidV,
    allowance: new BigNumber(rawTokenAllowance).toJSON(),
    balance: new BigNumber(rawTokenBalances).toJSON(),
    stakeAmount: new BigNumber(userStakeInfo.stakeAmount.toString()).toString(),
    stakePower: new BigNumber(userStakeInfo.stakePower.toString()).toString(),
    reward: new BigNumber(userRewardInfo.stakePendingRewardsRet.toString()).toString(),
    userMinStakeAmount: new BigNumber(userStakeRange.userMinStakeAmount.toString()).toString(),
    userMaxStakeAmount: new BigNumber(userStakeRange.userMaxStakeAmount.toString()).toString(),
    lastStakeTime: new BigNumber(getUserStakeInfo[0].lastStakeTime.toString()).times(1000).toNumber(),
  }
  return res
}

export const fetchFarmUserData = async(farms: Farm[],account: string)=>{
  const data:FarmUserData[] = await Promise.all(
    farms.map(async(farm)=>{
      if(farm.poolType===0){
        const resToken:FarmUserData = await fetchFarmUserToken(farm.factoryAddress,farm.pid,farm.stakeTokenAddress,account)
        return resToken
      }
      const resLp:FarmUserData = await fetchFarmUserLp(farm.factoryAddress,farm.pid,farm.stakeTokenAddress,account)
      return resLp
    })
  )
  return data
}
