import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import BigNumber from 'bignumber.js'
import { useWeb3React } from '@web3-react/core'
import { useTranslation } from 'contexts/Localization'
import Collapse from "@material-ui/core/Collapse";
import { useSnackbar } from 'notistack'

import InfoInput from '../CreatePool/InfoInput'
import ModalTheme from '../CreatePool/ModalTheme'
import InputLabel, { InputLabelNoTip } from '../CreatePool/InputLabel'
import TimerPicker from '../CreatePool/TimePicker'
import { useFarms } from '../../../../state/farms/hooks'
import PoolType from '../CreatePool/PoolType'
import { useApproveTokenToCreate } from '../../hooks/useApprove'
import useEditPool from '../../hooks/useEditPool'
import { Farm } from '../../../../state/types'
import { useGetBlockTimestampCallBack } from '../../../../hooks/useGetLastBlock'
import { useAppDispatch } from '../../../../state'
import { fetchFarmsPublicDataAsync, fetchFarmUserDataAsync } from '../../../../state/farms'
import { CreateOrEditPoolTips } from '../types'
import { ChainNameLogo } from '../../../../components/layout/ChainLogo'
import useClosePool from '../../hooks/useClosePool'
import { UiModalBtn } from '../../../components/UiLayout'
import UploadImg from '../CreatePool/UploadImg'

interface ModalProps {
  onDismiss?: () => void
  farm: Farm
}

const EditModal: React.FC<ModalProps> =
({
  onDismiss,
  farm
}) => {
  const { t } = useTranslation()
  const {account} = useWeb3React()

  const [stakedToken,setStakedToken] = useState(farm.stakeTokenAddress)
  const handleSetStakedToken = (event: React.ChangeEvent<HTMLInputElement>) => {
    setStakedToken(event.target.value)
  }

  const [poolType,setPoolType] = useState(farm.poolType)
  const handleSetPoolType = (event: number) => {
    setPoolType(event)
  }

  // poolname
  const [lpOrFormat,setLpOrFormat] = useState(farm.poolName)
  const handleSetLpOrFormat = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLpOrFormat(event.target.value)
  }

  // upload stake logo
  const [logoUrl,setLogoUrl] = useState(farm.poolViewInfo.stakedLogo)
  const handleSetLogoUrl = (event: string) => {
    setLogoUrl(event)
  }
  const [stakeTokenUsdt,setStakeTokenUsdt] = useState(farm.poolViewInfo.stakedPair)
  const handleSetStakeTokenUsdt = (event: React.ChangeEvent<HTMLInputElement>) => {
    setStakeTokenUsdt(event.target.value)
  }

  const [maxStakingAmountUser,setMaxStakingAmountUser] = useState(farm.userMaxStakeAmount.toString())
  const handleSetMaxStakingAmountUser = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMaxStakingAmountUser(event.target.value)
  }

  const [minStakingAmountUser,setMinStakingAmountUser] = useState(farm.userMinStakeAmount.toString())
  const handleSetMinStakingAmountUser = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMinStakingAmountUser(event.target.value)
  }

  const [poolHardCap,setPoolHardCap] = useState(farm.maxStakeAmount.toString())
  const handleSetPoolHardCap = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPoolHardCap(event.target.value)
  }

  const lockDay = farm.lockSeconds / 24/60/60
  const [chargeDaysOfStake,setChargeDaysOfStake] = useState(lockDay.toString())
  const handleSetChargeDaysOfStake = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChargeDaysOfStake(event.target.value)
  }

  const [unstakingFees,setUnstakingFees] = useState(farm.feeValue.toString())
  const handleSetUnstakingFees = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUnstakingFees(event.target.value)
  }

  const [rewardsToken,setRewardsToken] = useState(farm.rewardTokenSymbol)
  const handleSetRewardsToken = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRewardsToken(event.target.value)
  }

  const [totalRewards,setTotalRewards] = useState(farm.rewardTotals.toString())
  const handleSetTotalRewards = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTotalRewards(event.target.value)
  }

  const [rewardsContract,setRewardsContract] = useState(farm.rewardToken)
  const handleSetRewardsContract = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRewardsContract(event.target.value)
  }
  // upload reward logo
  const [rewardsLogoUrl,setRewardsLogoUrl] = useState(farm.poolViewInfo.rewardLogo)
  const handleSetRewardsLogoUrl = (event: string) => {
    setRewardsLogoUrl(event)
  }
  const [rewardTokenUsdt,setRewardTokenUsdt] = useState(farm.poolViewInfo.rewardPair)
  const handleSetRewardTokenUsdt = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRewardTokenUsdt(event.target.value)
  }

  const startT = useGetBlockTimestampCallBack(farm.startBlock)
  const [poolStarts,setPoolStarts] = useState<Date>(new Date())
  useEffect(()=>{
    setPoolStarts(new Date(startT))
  },[setPoolStarts,startT])
  const handleSetPoolStarts = (event: Date) => {
    setPoolStarts(event)
  }

  const [distributionDuration,setDistributionDuration] = useState<Date>(new Date(farm.mayEndTime))
  const handleSetDistributionDuration = (event: Date) => {
    setDistributionDuration(event)
  }

  const [officialWebsite,setOfficialWebsite] = useState(farm.poolViewInfo.officialSite)
  const handleSetOfficialWebsite = (event: React.ChangeEvent<HTMLInputElement>) => {
    setOfficialWebsite(event.target.value)
  }
  const [twitter,setTwitter] = useState(farm.poolViewInfo.twitter)
  const handleSetTwitter = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTwitter(event.target.value)
  }
  const [telegram,setTelegram] = useState(farm.poolViewInfo.telegram)
  const handleSetTelegram = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTelegram(event.target.value)
  }

  const [receiveFeeAddress,setReceiveFeeAddress] = useState(farm.feeAddress)
  const handleSetReceiveFeeAddress = (event: React.ChangeEvent<HTMLInputElement>) => {
    setReceiveFeeAddress(event.target.value)
  }

  const [pendingTx, setPendingTx] = useState(false)
  const {onApproveTokenToFactory,onAllowanceToken} = useApproveTokenToCreate()
  const [rewardsTokenAllowance,setRewardsTokenAllowance] = useState(false)
  useEffect(()=>{
    onAllowanceToken(rewardsContract).then((res)=>{
      setRewardsTokenAllowance(res)
    })
  },[
    setRewardsTokenAllowance,onAllowanceToken,
    rewardsContract,pendingTx,
  ])

  const {userSupportCommTokens} = useFarms()

  const supportCommToken = userSupportCommTokens.find((item)=>
    item.supportCommToken.toLowerCase()===farm.commissionTokenAddress.toLowerCase())
  const [userCanEdit,setUserCanEdit] = useState(false)
  const [userCanClose,setUserCanClose] = useState(false)
  useEffect(()=>{
    if( supportCommToken && new BigNumber(farm.poolCloseFeeValue).lte(new BigNumber(supportCommToken.userBalance))){
      setUserCanClose(true)
    }else{
      setUserCanClose(false)
    }
    if( supportCommToken && new BigNumber(farm.poolEditFeeValue).lte(new BigNumber(supportCommToken.userBalance))){
      setUserCanEdit(true)
    }else{
      setUserCanEdit(false)
    }
  },[
    supportCommToken,setUserCanClose,setUserCanEdit,farm
  ])

  // edit pool
  const {onEditPool} = useEditPool()
  const { enqueueSnackbar } = useSnackbar();
  const createPoolErrorText = t('Please complete the information')
  const dispatch = useAppDispatch()
  const {data:poolList} = useFarms()
  const handleCreateFun = async()=>{
    if(pendingTx||rewardsContract===''||!userCanEdit) return

    const inputInfo = {
      poolId: farm.pid,
      stakedTokenAddress: stakedToken,
      stakedTokenName: lpOrFormat,
      poolTypeV: poolType,
      userMaxStakeAmount: maxStakingAmountUser===''?'0':maxStakingAmountUser,
      userMinStakeAmount: minStakingAmountUser===''?'0':minStakingAmountUser,
      maxStakeAmount: poolHardCap===''?'0':poolHardCap,
      lockDays: chargeDaysOfStake===''?'0':chargeDaysOfStake,
      feeValue: unstakingFees===''?'0':unstakingFees,
      rewardsTokenName: rewardsToken,
      rewardTotals: totalRewards,
      rewardsTokenAddress: rewardsContract,
      startTime:poolStarts.getTime(),
      endTime: distributionDuration.getTime(),
      officialSite: officialWebsite,
      twitterLink: twitter,
      telegramLink: telegram,
      feeAddress: receiveFeeAddress===''?account:receiveFeeAddress,
      stakeTokenUsdt: stakeTokenUsdt.toString(),
      rewardTokenUsdt: rewardTokenUsdt.toString(),
      stakeLogo: logoUrl,
      rewardLogo: rewardsLogoUrl,
    }

    // check approve reward token
    if(!rewardsTokenAllowance){
      setPendingTx(true)
      const resAR = await onApproveTokenToFactory(rewardsContract)
      if(resAR){
        enqueueSnackbar('Approve Reward Token Successfully', {
          variant:'success' ,
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          autoHideDuration: 2500,
          TransitionComponent: Collapse,
        });
      }else{
        enqueueSnackbar('Please try again. Confirm the transaction and make sure you are paying enough gas!', {
          variant:'error' ,
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
          autoHideDuration: 2500,
          TransitionComponent: Collapse,
        });
      }
      setPendingTx(false)
      return
    }

    if(lpOrFormat===''||stakedToken===''||logoUrl===''||stakeTokenUsdt===''
      ||rewardsContract===''||rewardsLogoUrl===''||rewardTokenUsdt===''
      ||!poolStarts||!distributionDuration||totalRewards===''
    ) {
      enqueueSnackbar(createPoolErrorText, {
        variant:'error' ,
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
        autoHideDuration: 2500,
        TransitionComponent: Collapse,
      });
      return
    }
    // check input
    // check poolname length
    if(lpOrFormat.length>40){
      enqueueSnackbar('Please short Your Pool Name', {
        variant:'error' ,
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
        autoHideDuration: 2500,
        TransitionComponent: Collapse,
      });
      return
    }
    // check user userMaxStakeAmount/ userMinStakeAmount
    if(inputInfo.userMaxStakeAmount!== '0' && (Number(inputInfo.userMinStakeAmount)>Number(inputInfo.userMaxStakeAmount))){
      enqueueSnackbar('Staking Lower Limit per User is big than Upper ', {
        variant:'error' ,
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
        autoHideDuration: 2500,
        TransitionComponent: Collapse,
      });
      return
    }
    // if user min/max stake has set, the maxStakeAmount must  set
    // maxStakeAmount === 0 &&  userMinStakeAmount/userMaxStakeAmount !==0
    if(inputInfo.userMinStakeAmount!=='0'||inputInfo.userMaxStakeAmount!=='0'){
      if(inputInfo.maxStakeAmount==='0'){
        enqueueSnackbar('Pool Hard Cap must set, and it need big than Staking Lower or Upper Limit per User', {
          variant:'error' ,
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
          autoHideDuration: 2500,
          TransitionComponent: Collapse,
        });
        return
      }
      if(inputInfo.maxStakeAmount!=='0' && (Number(inputInfo.userMinStakeAmount)>Number(inputInfo.maxStakeAmount)||Number(inputInfo.userMaxStakeAmount)>Number(inputInfo.maxStakeAmount))){
        enqueueSnackbar('Pool Hard Cap must big than Staking Lower or Upper Limit per User', {
          variant:'error' ,
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
          autoHideDuration: 2500,
          TransitionComponent: Collapse,
        });
        return
      }
    }

    // edit pool action
    setPendingTx(true)
    const res = await onEditPool(inputInfo,farm)
    if(res){
      enqueueSnackbar('Edit Pool Successfully', {
        variant:'success' ,
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
        autoHideDuration: 2500,
        TransitionComponent: Collapse,
      });
      dispatch(fetchFarmsPublicDataAsync())
      dispatch(fetchFarmUserDataAsync({account:account.toString(),farms:poolList}))
      onDismiss()
    }else{
      enqueueSnackbar('Edit Pool Fail! Please check the information', {
        variant:'error' ,
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
        autoHideDuration: 2500,
        TransitionComponent: Collapse,
      });
    }
    setPendingTx(false)
  }

  const {onClosePool} = useClosePool()
  const handleClosePoolFun = async()=>{
    if(pendingTx || !userCanClose) return
    setPendingTx(true)
    const res = await onClosePool(farm.pid)
    if(res){
      enqueueSnackbar('Close Pool Successfully', {
        variant:'success' ,
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
        autoHideDuration: 2500,
        TransitionComponent: Collapse,
      });
      dispatch(fetchFarmsPublicDataAsync())
      dispatch(fetchFarmUserDataAsync({account:account.toString(),farms:poolList}))
      onDismiss()
    }
    setPendingTx(false)
  }

  return (
    <ModalTheme title="Edit Pool" onDismiss={onDismiss}>
      <ModalBox>
        <StyledInputRow>
          <InputLabelNoTip label={t('Chain')}/>
          <div style={{flex: 1,color: '#fff'}}>
            <ChainNameLogo/>
          </div>
        </StyledInputRow>
        <StyledInputRow>
          <InputLabel
            label={t('Pool Name')}
            tooltipId="lp-format"
            tipsText={CreateOrEditPoolTips.poolName}
          />
          <InfoInput value={lpOrFormat} onChange={handleSetLpOrFormat} placeholder=""/>
        </StyledInputRow>
        <StyledInputRow>
          <InputLabel
            label={t('Pool Type')}
            tooltipId="pool-type"
            tipsText={CreateOrEditPoolTips.poolType}
          />
          <PoolType onChange={handleSetPoolType} poolType={poolType}/>
        </StyledInputRow>
        <StyledInputRow>
          <InputLabel
            label={t('Official Website')}
            tooltipId="official-website"
            tipsText={CreateOrEditPoolTips.officialWebsite}
          />
          <InfoInput value={officialWebsite} onChange={handleSetOfficialWebsite} placeholder="Official Website"/>
        </StyledInputRow>
        <StyledInputRow>
          <InputLabel
            label={t('Staking Token/LP Address')}
            tooltipId="staked-token"
            tipsText={CreateOrEditPoolTips.stakeTokenLpAddress}
          />
          <InfoInput value={stakedToken} onChange={handleSetStakedToken} placeholder=""/>
        </StyledInputRow>
        <StyledInputRow>
          <InputLabel
            label={t('Reward Token Address')}
            tooltipId="rewards-contract"
            tipsText={CreateOrEditPoolTips.rewardTokenAddress}
          />
          <InfoInput value={rewardsContract} onChange={handleSetRewardsContract} placeholder=""/>
        </StyledInputRow>
        <StyledInputRow>
          <InputLabel
            label={t('Staking Token/LP LOGO')}
            tooltipId="logo-url"
            tipsText={CreateOrEditPoolTips.stakeTokenLpLogoUrl}
          />
          <UploadImg inputId="staking-logo-input" value={logoUrl} onChange={handleSetLogoUrl}/>
        </StyledInputRow>
        <StyledInputRow>
          <InputLabel
            label={t('Reward Token LOGO')}
            tooltipId="rewards-logo-url"
            tipsText={CreateOrEditPoolTips.rewardTokenLogoUrl}
          />
          <UploadImg inputId="reward-logo-input" value={rewardsLogoUrl} onChange={handleSetRewardsLogoUrl}/>
        </StyledInputRow>
        <StyledInputRow>
          <InputLabel
            label={t('Staking Token Pair Address')}
            tooltipId="stake-token-usdt-pair"
            tipsText={CreateOrEditPoolTips.stakingTokenPairAddress}
          />
          <InfoInput value={stakeTokenUsdt} onChange={handleSetStakeTokenUsdt} placeholder=""/>
        </StyledInputRow>
        <StyledInputRow>
          <InputLabel
            label={t('Reward Token Pair Address')}
            tooltipId="reward-token-usdt-pair"
            tipsText={CreateOrEditPoolTips.rewardTokenPairAddress}
          />
          <InfoInput value={rewardTokenUsdt} onChange={handleSetRewardTokenUsdt} placeholder=""/>
        </StyledInputRow>
        <StyledInputRow>
          <InputLabel
            label={t('Total Rewards')}
            tooltipId="total-rewards"
            tipsText={CreateOrEditPoolTips.totalRewards}
          />
          <InfoInput value={totalRewards} onChange={handleSetTotalRewards} placeholder="Total Rewards"/>
        </StyledInputRow>
        <StyledInputRow>
          <InputLabel
            label={t('Pool Starting Time')}
            tooltipId="pool-starts"
            tipsText={CreateOrEditPoolTips.poolStartingTime}
          />
          <TimerPicker value={poolStarts} onChange={handleSetPoolStarts}/>
        </StyledInputRow>
        <StyledInputRow>
          <InputLabel
            label={t('Pool Ending Time')}
            tooltipId="distribution-duration"
            tipsText={CreateOrEditPoolTips.poolEndingTime}
          />
          <TimerPicker value={distributionDuration} onChange={handleSetDistributionDuration}/>
        </StyledInputRow>
        <StyledInputRow>
          <InputLabel
            label={t('Set Duration(day) for unstaking fees (Optional)')}
            tooltipId="charge-days-staking"
            tipsText={CreateOrEditPoolTips.lockedDuration}
          />
          <InfoInput value={chargeDaysOfStake} onChange={handleSetChargeDaysOfStake} placeholder="days"/>
        </StyledInputRow>
        <StyledInputRow>
          <InputLabel
            label={t('Set Unstaking Fees (%) in the Duration (Optional)')}
            tooltipId="unstaking-fees"
            tipsText={CreateOrEditPoolTips.unstakingFeesInLockedDuration}
          />
          <InfoInput value={unstakingFees} onChange={handleSetUnstakingFees} placeholder="Unstaking Fees"/>
        </StyledInputRow>
        <StyledInputRow>
          <InputLabel
            label={t('Address to Receive Unstaking Fees (Optional)')}
            tooltipId="receive-fee-address"
            tipsText={CreateOrEditPoolTips.addressToReceiveUnstakingFees}
          />
          <InfoInput value={receiveFeeAddress} onChange={handleSetReceiveFeeAddress} placeholder="Receive Fee Address"/>
        </StyledInputRow>
        <StyledInputRow>
          <InputLabel
            label={t('Staking Upper Limit per User (Optional)')}
            tooltipId="max-staking-user"
            tipsText={CreateOrEditPoolTips.stakingUpperLimitPerUser}
          />
          <InfoInput value={maxStakingAmountUser} onChange={handleSetMaxStakingAmountUser} placeholder="0"/>
        </StyledInputRow>
        <StyledInputRow>
          <InputLabel
            label={t('Staking Lower Limit per User (Optional)')}
            tooltipId="min-staking-user"
            tipsText={CreateOrEditPoolTips.stakingLowerLimitPerUser}
          />
          <InfoInput value={minStakingAmountUser} onChange={handleSetMinStakingAmountUser} placeholder="0"/>
        </StyledInputRow>
        <StyledInputRow>
          <InputLabel
            label={t('Pool Hard Cap (Optional)')}
            tooltipId="the-pool-hard-cap"
            tipsText={CreateOrEditPoolTips.poolHardCap}
          />
          <InfoInput value={poolHardCap} onChange={handleSetPoolHardCap} placeholder="0"/>
        </StyledInputRow>
        <StyledInputRow>
          <InputLabel
            label={t('Twitter(Optional)')}
            tooltipId="twitter"
            tipsText={CreateOrEditPoolTips.twitter}
          />
          <InfoInput value={twitter} onChange={handleSetTwitter} placeholder="Twitter"/>
        </StyledInputRow>
        <StyledInputRow>
          <InputLabel
            label={t('Telegram(Optional)')}
            tooltipId="telegram"
            tipsText={CreateOrEditPoolTips.telegram}
          />
          <InfoInput value={telegram} onChange={handleSetTelegram} placeholder="Telegram"/>
        </StyledInputRow>

        <StyledInputRow>
          {!rewardsTokenAllowance&&(
            <UiModalBtn
              className={`${pendingTx||rewardsContract==='' ?'disable':''} mr mb`}
              onClick={handleCreateFun}
            >
              <h3>{t('Approve Reward Token')}</h3>
            </UiModalBtn>
          )}
          {rewardsTokenAllowance&&(
            <UiModalBtn
              className={`${pendingTx || !userCanEdit ?'disable':''} mr mb`}
              onClick={handleCreateFun}
            >
              {rewardsTokenAllowance && userCanEdit && (
                <h3>{pendingTx ? t('Loading (10 sec)'):t('Edit')}</h3>
              )}
              {!pendingTx && !userCanEdit && (
                <h4>{`Insufficient ${farm.commissionTokenSymbol} Balance`}</h4>
              )}
              {!pendingTx && userCanEdit && (
                <h4>{farm.poolEditFeeValue} {farm.commissionTokenSymbol}</h4>
              )}
            </UiModalBtn>
          )}

          <UiModalBtn
            className={pendingTx || !userCanClose ?'disable':''}
            onClick={handleClosePoolFun}
          >
            <h3>{t('Close Pool')}</h3>
            {!pendingTx && !userCanClose && (
              <h4>{`Insufficient ${farm.commissionTokenSymbol} Balance`}</h4>
            )}
            {!pendingTx && userCanClose && (
              <h4>{farm.poolCloseFeeValue} {farm.commissionTokenSymbol}</h4>
            )}

          </UiModalBtn>
        </StyledInputRow>
      </ModalBox>
    </ModalTheme>
  )
}

const ModalBox = styled.div`
  width: 100%;
  max-height: 300px;
  overflow: auto;
  padding-right: 10px;
  ${({ theme }) => theme.mediaQueries.sm} {
    width: 500px;
    max-height: 500px;
  } 
`
const StyledInputRow = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-bottom: 10px;
  ${({ theme }) => theme.mediaQueries.sm} {
    flex-direction: row;
    align-items: center;
  } 
`

export default EditModal
